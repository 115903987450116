
// UserSessions.jsx
import React, { useState, useEffect } from 'react';
import { formatDateStrict } from '../utils/dateUtils';  
import { convertTimeTo12HourFormat } from '../utils/timeUtils';
import UserSessionFeedbackSubmission from './UserSessionFeedbackSubmission';
import UserSessionFeedbackView from './UserSessionFeedbackView';
import { Alert, IconButton, Button, Box, Tabs, Tab, Tooltip, Typography, Accordion, 
    AccordionSummary, AccordionDetails, Grid, CircularProgress, Paper } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { format, parse, differenceInHours } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

const UserSessions = ({ setSuccessMessage, setErrorMessage, csrfToken }) => {
  const [subTab, setSubTab] = useState(0);
  const [upcomingSessions, setUpcomingSessions] = useState([]);
  const [pastSessions, setPastSessions] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [disableVerticalScroll, setDisableVerticalScroll] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState(''); 
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showFeedbackViewModal, setShowFeedbackViewModal] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  
  let utz;
  try {
      utz = Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Los_Angeles';
  } catch (error) {
      // In case of any unexpected error, default to America/Los_Angeles
      utz = 'America/Los_Angeles';
  }

  // Fetch user sessions (upcoming and past)
  const fetchUserSessions = async () => {  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user/sessions/`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-User-Timezone': utz,  // Pass user timezone to backend
          'X-CSRFToken': csrfToken, 
        }
      });
  
      if (response.ok) {
        const data = await response.json();
        setUpcomingSessions(data.upcoming_sessions || []); // Set upcoming sessions
        setPastSessions(data.past_sessions || []); // Set past sessions

        // Optional: Log or inspect the past sessions to check the new field
        console.log('Past sessions with feedback status:', data.past_sessions);
      } else {
        console.error('Failed to fetch sessions:', await response.json());
      }
    } catch (error) {
      console.error('Error fetching sessions:', error);
      setErrorMessage('An unexpected error occurred.');
    } finally {
      setLoading(false); // Stop loading after fetching data
    }
  };
  
  useEffect(() => {
    fetchUserSessions();
  }, []);
  
  const handleSubTabChange = (event, newValue) => {
    setSubTab(newValue);
  };
  
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    if (isExpanded) {
        setExpandedPanels((prev) => [...prev, panel]); // Add panel to expandedPanels array
        setDisableVerticalScroll(true);
      } else {
        setExpandedPanels((prev) => prev.filter((p) => p !== panel)); // Remove panel from expandedPanels array
      }

    // Re-enable scroll after the animation is complete
    setTimeout(() => setDisableVerticalScroll(false), 300);
  };

  
  // Creates a timezone-aware Date object using the provided date, time, and user timezone.
  const getSessionEndTime = (session) => {
    const { date, end_time, user_time_zone } = session;
    const dateTimeString = `${date}T${end_time}`;
    const zonedDate = formatInTimeZone(dateTimeString, user_time_zone, 'yyyy-MM-dd HH:mm:ssXXX');
    
    return zonedDate;
  };

  // Helper function to get current time in user's time zone
  const getCurrentTimeInUserTimeZone = (user_time_zone) => {
    const now = new Date();
    const currentTimeInUserTimeZone = formatInTimeZone(now, user_time_zone, 'yyyy-MM-dd HH:mm:ssXXX');
    
    return currentTimeInUserTimeZone;
  };

  const handleButtonClick = async (action, session) => {
    if (!session) {
      console.error('Session is null or undefined');
      return;
    }
  
    // Handle the "Provide Feedback" action separately
    if (action === 'Provide Feedback') {
      setSelectedSession(session);
      setShowFeedbackModal(true); // Open the feedback modal
      return; // Exit the function early, no need to show alert
    }
  
    // Handle the "Feedback Provided - View" action separately
    if (action === 'Feedback Provided - View') {
      setSelectedSession(session);

      // Fetch the feedback data for the selected session
      const feedbackData = await fetchSessionFeedback(session.id);
      if (feedbackData) {
      // Pass the fetched feedback data to the selected session object
      const sessionWithFeedback = { ...session, ...feedbackData };
      setSelectedSession(sessionWithFeedback); // Update the selected session with feedback data
      }

      setShowFeedbackViewModal(true); // Open view modal
      return;
    }

    // Define the message based on other action types
    let message;
    if (action === 'Seek Refund') {
      message = `Please contact support@eastbadge.com to seek a refund for this session.`;
    } else {
      message = `Please contact support@eastbadge.com to ${action.toLowerCase()} this session.`;
    }
  
    // Set alert message and display it only for actions other than "Provide Feedback"
    setAlertMessage(message);
    setShowDeleteMessage(true);
  };

  const handleModalClose = () => {
    setShowFeedbackModal(false);
    setShowFeedbackViewModal(false);
    setSelectedSession(null); // Clear selected session on close
  };

  const handleFeedbackSubmit = async (feedbackData) => {
    if (!selectedSession || !selectedSession.id) {
        setErrorMessage('Session not selected or invalid session ID.');
      return;
    }
    try {
      // Make POST request to the feedback submission endpoint with session cookies
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/user/sessions/${selectedSession.id}/feedback/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        credentials: 'include', // Ensure cookies are sent with the request
        body: JSON.stringify(feedbackData),
      });
  
      if (response.ok) {
        const result = await response.json();
        setSuccessMessage('Feedback submitted successfully.');
        setShowFeedbackModal(false);
        setSelectedSession(null); // Clear selected session on success
        // Optionally, refresh session list to show feedback status
        await fetchUserSessions();
      } else {
        const errorData = await response.json();
        setErrorMessage(`Failed to submit feedback: ${errorData.error || 'Unknown error'}`);
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setErrorMessage('An unexpected error occurred while submitting feedback.');
    }
  };


  const fetchSessionFeedback = async (sessionId) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/sessions/${sessionId}/feedback/`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,  // Pass CSRF token for security
          },
        }
      );
  
      if (response.ok) {
        const feedbackData = await response.json();
        return feedbackData;  // Return the fetched feedback data
      } else {
        console.error('Failed to fetch session feedback:', await response.json());
        setErrorMessage('Failed to fetch session feedback.');
        return null;
      }
    } catch (error) {
      console.error('Error fetching session feedback:', error);
      setErrorMessage('An unexpected error occurred while fetching feedback.');
      return null;
    }
  };

  useEffect(() => {
    if (showDeleteMessage) {
      const timer = setTimeout(() => {
        setShowDeleteMessage(false);
      }, 5000); // Auto-dismiss after 5 seconds

      return () => clearTimeout(timer); // Clear the timer if component unmounts or showDeleteMessage changes
    }
  }, [showDeleteMessage]);
  
  const renderSessionAccordion = (sessions, isUpcoming) => {
    return sessions.map((session, index) => {
      const sessionEndTime = getSessionEndTime(session);
      const currentTime = getCurrentTimeInUserTimeZone(session.user_time_zone);
      const hoursSinceEndTime = sessionEndTime ? differenceInHours(new Date(currentTime), new Date(sessionEndTime)) : 0;

      // Disable "Seek Refund" button if status is not 'completed' or 12 hours have passed
      const isRefundDisabled = session.status !== 'completed' || hoursSinceEndTime > 12;

      // Define tooltip message for the info icon when button is disabled
      const refundTooltipMessage = session.status !== 'completed'
      ? "Session status must be 'Completed' to seek refund."
      : "12-hour window to seek session refund has elapsed.";
     
      // Determine if "Provide Session Feedback" should be disabled
      // const isFeedbackDisabled = session.status !== 'completed' || hoursSinceEndTime > 48;

      // Logic for feedback button display based on session status and feedback submission
      let feedbackButtonLabel = 'Provide Session Feedback';
      let feedbackButtonColor = '#4caf50';
      let feedbackButtonDisabled = false;
      let feedbackAction = 'Provide Feedback';
      let feedbackTooltipMessage = '';

      if (session.user_feedback_submitted) {
        // Feedback already submitted
        feedbackButtonLabel = 'Feedback Provided - View';
        feedbackButtonColor = 'gray';
        feedbackButtonDisabled = false; // Active button to view feedback
        feedbackAction = 'Feedback Provided - View';
      } else if (session.status === 'completed' && hoursSinceEndTime <= 48) {
        // Feedback not submitted and within the 48-hour window
        feedbackButtonDisabled = false; // Active button for feedback submission
      } else {
        // Feedback not submitted, but session not completed or outside the 48-hour window
        feedbackButtonDisabled = true;
        
        if (session.status !== 'completed') {
            feedbackTooltipMessage = "Session status must be 'Completed' to provide feedback.";
        } else {
            feedbackTooltipMessage = "48-hour window to provide session feedback has elapsed.";
        }
      }
      
      return (
        <Accordion
          key={session.session_uuid}
          expanded={expandedPanels.includes(`panel${index}`)}
          onChange={handleAccordionChange(`panel${index}`)}
          sx={{
            width: '100%',
            mt: 2,
            mb: 1,
            padding: 0,
            boxShadow: 'none',
            '&:before': { display: 'none' }, // Remove the default Accordion border
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{ padding: 0, margin: 0 }} // Remove padding and margin to reduce shifting
          >
            <Grid container spacing={0} alignItems="center" sx={{ pt: 0, pb: 0 }} >
              
              <Grid item xs={2} display="flex" justifyContent="center">
                <Typography variant="body2" textAlign="center">
                  {session.session_uuid.slice(-6).toUpperCase()}
                </Typography>
              </Grid>
              
              <Grid item xs={4} display="flex" justifyContent="center" sx={{ ml: 1 }}>
                <Box textAlign="center">
                  <Typography variant="body2">
                    Coach: {session.coach_unique_username}
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={3} display="flex" justifyContent="center" sx={{ ml: 1 }}>
                <Box textAlign="center">
                  <Typography variant="body2">
                    {`${formatDateStrict(session.date)}, ${format(parse(session.date, 'yyyy-MM-dd', new Date()), 'EEE')}`}
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={2} display="flex" justifyContent="center" sx={{ ml: 4 }}>
                <Typography variant="body2" textAlign="center">
                  {session.status.charAt(0).toUpperCase() + session.status.slice(1)}
                </Typography>
              </Grid>
              
              {/* Empty cell for expand icon alignment */}
              <Grid item xs={1} />
            </Grid>
          </AccordionSummary>
          {/* AccordionDetails to show additional content when expanded */}
          <AccordionDetails 
            sx={{ 
                padding: 0, 
                margin: 0,
                animation: expandedPanels.includes(`panel${index}`)
                ? 'expandAccordion 0.3s ease forwards'
                : 'collapseAccordion 0.3s ease forwards',
                '@keyframes expandAccordion': {
                from: { height: 0, opacity: 0 },
                to: { height: 'auto', opacity: 1 },
                },
                '@keyframes collapseAccordion': {
                from: { height: 'auto', opacity: 1 },
                to: { height: 0, opacity: 0 },
                },
             }}
          >
            <Grid container spacing={0} alignItems="center">
              
              <Grid item xs={2} />
              
              <Grid item xs={4} display="flex" justifyContent="center" sx={{ mt: -2, mb: -1, }}>
                <Box textAlign="center">
                  <Typography variant="body2">
                    {session.target_role}
                  </Typography>
              
                  <Typography variant="body2">
                    {session.target_company}
                  </Typography>
              
                  <Box sx={{ height: 10 }} />
              
                  <Typography variant="body2">
                    {session.product_offering_type}
                  </Typography>
                
                  <Typography variant="body2">
                    {session.product_name}
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={3} display="flex" justifyContent="center" sx={{ mt: -9.5, mb: -1, }}>
                <Box textAlign="center">
                  <Typography variant="body2">
                    {`${convertTimeTo12HourFormat(session.start_time)} - ${convertTimeTo12HourFormat(session.end_time)}`}
                  </Typography>
              
                  <Typography variant="body2">
                    {session.user_time_zone}
                  </Typography>
                </Box>
              </Grid>
              
              <Grid item xs={2} />
              
              {/* Control empty space below expanded content */}
              <Grid item xs={1} />
            </Grid>

            {/* Button Container */}
            {/* Conditionally render buttons for Upcoming sessions */}
            <Grid item xs={2} display="flex" justifyContent="flex-end" sx={{ mt: 3, mr: 2 }}>
              {isUpcoming ? (
                <>
                  {/* Modify and Cancel Buttons */}
                  <Button
                    variant="outlined" // Use 'outlined' variant for a cleaner look
                    size="medium"
                    onClick={() => handleButtonClick('Modify', session)}
                    sx={{
                      mr: 3,
                      textTransform: 'capitalize',
                      bgcolor: '#1890ff',
                      '&:hover': { bgcolor: '#1071e5' },
                      color: '#fff',
                      height: { xs: '40px', sm: '42px' },  // Responsive button height
                      width: { xs: '100px', sm: '140px' },
                    }}
                  >
                    Modify
                  </Button>
              
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => handleButtonClick('Cancel', session)}
                    sx={{
                      textTransform: 'capitalize',
                      bgcolor: '#1890ff',
                      '&:hover': { bgcolor: '#1071e5' },
                      color: '#fff',
                      height: { xs: '40px', sm: '42px' },  // Responsive button height
                      width: { xs: '100px', sm: '140px' },
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  {/* Provide/ View Feedback and Seek Refund Buttons for Past Sessions */}
                  {/* Show Info Icon when Feedback button is disabled */}
                  {feedbackButtonDisabled && feedbackTooltipMessage && (
                    <Tooltip 
                      title={
                        feedbackTooltipMessage
                      }
                    >
                      <Box sx={{ display: 'flex', mr: 0 }}>
                        <IconButton>
                            <InfoOutlinedIcon 
                            sx={{ 
                                color: '#bdbdbd',
                                '&:hover': { borderRadius: '100%' },
                            }} 
                            />
                        </IconButton>
                      </Box>
                    </Tooltip>
                  )}
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => handleButtonClick(feedbackAction, session)}
                    disabled={feedbackButtonDisabled}
                    sx={{
                      textTransform: 'capitalize',
                      borderColor: feedbackButtonColor,
                      bgcolor: feedbackButtonColor,
                      color: '#fff', 
                      '&:hover': { 
                        borderColor: feedbackButtonColor === 'gray' ? '#616161' : '#388e3c',
                        bgcolor: feedbackButtonColor === 'gray' ? '#616161' : '#388e3c',
                      },
                      height: { xs: '40px', sm: '42px' },
                      width: { xs: '100px', sm: '250px' },
                      mr: 4,
                      '&.Mui-disabled': { // Override default disabled styles
                        borderColor: '#bdbdbd',
                        bgcolor: '#e0e0e0',
                        color: '#9e9e9e',   
                      },
                    }}
                  >
                    {feedbackButtonLabel}
                  </Button>
                  {/* Seek Refund Button */}
                  {isRefundDisabled && (
                    <Tooltip 
                    title={refundTooltipMessage}
                    >
                      <Box sx={{ display: 'flex', mr: 0 }}>
                        <IconButton>
                          <InfoOutlinedIcon 
                          sx={{ 
                              color: '#bdbdbd',
                              '&:hover': { borderRadius: '100%' },
                          }} 
                          />
                      </IconButton>
                    </Box>
                  </Tooltip>
                  )}
                  <Button
                    variant="outlined"
                    size="medium"
                    onClick={() => handleButtonClick('Seek Refund', session)}
                    disabled={isRefundDisabled}
                    sx={{
                      width: '130px',
                      textTransform: 'capitalize',
                      bgcolor: '#1890ff',
                      '&:hover': { bgcolor: '#1071e5' },
                      color: '#fff',
                      height: { xs: '40px', sm: '42px' },  // Responsive button height
                      width: { xs: '100px', sm: '140px' },
                      '&.Mui-disabled': { // Override default disabled styles
                        borderColor: '#bdbdbd',
                        bgcolor: '#e0e0e0',
                        color: '#9e9e9e',
                      },
                    }}
                  >
                    Seek Refund
                  </Button>
                </>
              )}
            </Grid>
          </AccordionDetails>
        </Accordion>
      );
    });
  };
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </div>
    );
  }
  return (
    <Box 
      sx={{ 
        border: '1px solid #f5f5f5',   
        borderRadius: 1,               
        pl: 10,
        pr: 10,
        pt: 5,
        pb: 5,                    
        width: '80%',
        marginTop: -3,
        marginLeft: -2,                   
        display: 'flex', 
        flexDirection: 'column',
        gap: 2,                       
        mb: 6,
        overflowY: disableVerticalScroll ? 'hidden' : 'auto',
        overflowX: { xs: 'auto', md: 'hidden' },                         
      }}
    >
      <Box sx={{ minWidth: { xs: '600px', md: '100%' } }}>
      <Tabs 
        value={subTab} 
        onChange={handleSubTabChange} 
        sx={{
          borderBottom: 1, 
          borderColor: 'divider', 
          '.MuiTabs-indicator': { 
            backgroundColor: 'transparent' 
          }
        }}
      >
        <Tab 
          label="Upcoming" 
          sx={{ 
            textTransform: 'none', 
            fontSize: '1rem',
            pl: 0,
            pr: 0,
            justifyContent: 'flex-start',
            width: '120px', 
            minWidth: '120px',
            '&.Mui-selected': {  
              color: '#5c5c5c',    
              borderBottom: '4px solid #5c5c5c', 
              borderRadius: 1,
            },
            '&:hover': {
              bgcolor: '#eeeeee',  
            },
          }}
        />
        <Tab 
          label="Past" 
          sx={{ 
            textTransform: 'none', 
            fontSize: '1rem',
            pl: 0,
            pr: 0,
            justifyContent: 'flex-start',
            width: '120px', 
            minWidth: '120px',
            '&.Mui-selected': {  
              color: '#5c5c5c',    
              borderBottom: '4px solid #5c5c5c', 
              borderRadius: 1,
            },
            '&:hover': {
              bgcolor: '#eeeeee',  
            },
          }}
        />
      </Tabs>
      {/* Header Section */}
      <Paper 
        elevation={0} 
        sx={{
            pt: 1,
            pb: 2,  
            width: '100%',  
            margin: '0 auto',  
            // borderBottom: '1px solid #e0e0e0', // Add a border at the bottom to give a table header effect
        }}
        >
        <Grid container spacing={2} justifyContent="space-between" alignItems="center" sx={{ pt: 0, pb: 0, mt: 0, mb: -5 }}>
            {/* SessionID Header */}
            <Grid item xs={2} display="flex" justifyContent="center">
              <Typography variant="body1" fontWeight="bold" color="#5c5c5c" textAlign="center">SessionID</Typography>
            </Grid>
            {/* Coach and Session Type Header */}
            <Grid item xs={4} display="flex" justifyContent="center">
              <Typography variant="body1" fontWeight="bold" color="#5c5c5c" textAlign="center">Details</Typography>
            </Grid>
            {/* Date and Time Header */}
            <Grid item xs={3} display="flex" justifyContent="center">
              <Typography variant="body1" fontWeight="bold" color="#5c5c5c" textAlign="center">Schedule</Typography>
            </Grid>
            {/* Status Header */}
            <Grid item xs={3} display="flex" justifyContent="center" sx={{ pr: 2 }}>
              <Typography variant="body1" fontWeight="bold" color="#5c5c5c" textAlign="center">Status</Typography>
            </Grid>
            {/* Empty cell for expand icon */}
            <Grid item xs={1}></Grid>
        </Grid>
      </Paper>
      {/* Upcoming Sessions */}
      {subTab === 0 && (
      <Box sx={{ mt: 2 }}>
        {upcomingSessions.length === 0 ? (
        <Typography>No upcoming sessions.</Typography>
        ) : (
        renderSessionAccordion(upcomingSessions, true) // Pass `true` to indicate these are Upcoming sessions
        )}
      </Box>
      )}
      {/* Past Sessions */}
      {subTab === 1 && (
      <Box sx={{ mt: 2 }}>
        {pastSessions.length === 0 ? (
        <Typography>No past sessions.</Typography>
        ) : (
        renderSessionAccordion(pastSessions, false) // Pass `false` to indicate these are Past sessions
        )}
      </Box>
      )}
      </Box>

      {/* Show the Alert when message is active */}
      {showDeleteMessage && (
        <Box sx={{ mt: 2 }}>
          <Alert
            severity="info"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => setShowDeleteMessage(false)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {alertMessage}
          </Alert>
        </Box>
      )}
      <UserSessionFeedbackSubmission
        open={showFeedbackModal}
        onClose={handleModalClose}
        session={selectedSession}
        onSubmit={handleFeedbackSubmit}
        csrfToken={csrfToken} 
      />

      <UserSessionFeedbackView 
        open={showFeedbackViewModal} 
        onClose={handleModalClose} 
        session={selectedSession} 
        csrfToken={csrfToken}
      />
    </Box>
  );
};
export default UserSessions;
